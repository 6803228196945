<template>
  <div class="accounting-create-final-invoice pt-4 pb-8 w-full">
    <p class="title">Abschlussrechnung erstellen</p>

    <vs-row class="mt-4">
      <vs-alert color="primary" :active="true" icon="info" class="mb-4" >
        DIESE FUNKTION MUSS NOCH ENTWICKELT WERDEN!!
      </vs-alert>
    </vs-row>


    <vs-row class="pt-4">
      <vs-alert color="primary" :active="true" icon="info" class="mb-4" >
        Eine Abschlussrechnung ist nur möglich, wenn eine schriftliche Freigabe unserer Arbeiten vorliegt!
      </vs-alert>
    </vs-row>
    <vs-row vs-w="6" class="block">
      <div class="flex pb-2 border-bottom-light">
        <div class="w-1/5"><vs-checkbox></vs-checkbox></div>
        <div class="w-1/2">Ich habe eine Freigabe vorliegen!</div>
      </div>
      <div class="flex pt-4">
        <div class="w-1/5">Typ:</div>
        <div class="w-1/2">
          <router-link
              @click.native="selectFixedPriceCheckbox"
              :to="{name:'offers-accounting-create-final-invoice-fixed-price'}"
              class="NavigationItem__router-link type-link">
            <vs-radio v-model="fixedPriceCheckbox"   class="pr-8" vs-name="radios1" vs-value="1"> Festpreis</vs-radio>
          </router-link>
          <router-link
              @click.native="selectOfferItemsCheckbox"
              :to="{name:'offers-accounting-create-final-invoice-offer-items'}"
              class="NavigationItem__router-link type-link">
            <vs-radio v-model="offerItemsCheckbox" vs-name="radios2" vs-value="1">Variable Angebotspositionen</vs-radio>
          </router-link>
        </div>
      </div>
    </vs-row>
    <vs-row>
      <vs-col vs-type="flex"  class="content w-full" vs-justify="left"  vs-w="10">
        <transition :name="routerTransition" mode="out-in">
          <router-view @changeRouteTitle="changeRouteTitle"></router-view>
        </transition>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>

import themeConfig from '@/../themeConfig.js'

export default {
  name: "AccountingCreateFinalInvoice",
  components: {
    themeConfig
  },
  data() {
    return {
      fixedPriceCheckbox: true,
      offerItemsCheckbox: false,
      routerTransition  : themeConfig.routerTransition || 'none',
      routeTitle        : this.$route.meta.pageTitle || '',
    }
  },
  methods: {
    changeRouteTitle(title) {
      this.routeTitle = title
    },
    selectOfferItemsCheckbox() {
      if (this.fixedPriceCheckbox === true) {
        this.fixedPriceCheckbox = false
        this.offerItemsCheckbox = true
      }
    },
    selectFixedPriceCheckbox() {
      if (this.offerItemsCheckbox === true) {
        this.offerItemsCheckbox = false
        this.fixedPriceCheckbox = true
      }
    }
  },
  watch: {
    "$route"() {
      this.routeTitle = this.$route.meta.pageTitle
    },
  }
}
</script>

<style lang="scss">
.accounting-create-final-invoice {
  .title {
    font-weight: bold;
    font-size: 15px;
  }
  .border-bottom-light {
    border-bottom: 1px solid #dccbcb;
  }
  .draft-invoice {
    color: #71c73f;
    .material-icons {
      color: inherit;
      font-weight: bold;
    }
  }
  .billing-items {
    color: #e24733;
    .material-icons {
      color: inherit;
      font-weight: bold;
    }
  }
  .amount {
    font-size: 16px;
    font-weight: bold;
  }
  .type-link {
    color: #626262;
  }
}
</style>
